<template>
  <div class="content-page uk-body">
    <div class="calendar">
      <div class="day" @click="datePickerShow = true">
        {{ `${monthStr[month - 1]},${date} ${weekStr[day]}` }}
      </div>
      <table class="sign_tab" border="0px" cellpadding="0px" cellspacing="0px">
        <thead>
          <tr>
            <th>S</th>
            <th>M</th>
            <th>T</th>
            <th>W</th>
            <th>T</th>
            <th>F</th>
            <th>S</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in dateArr.filter((_item) => contains(_item))"
            :key="index"
          >
            <td
              v-for="(d_item, d_index) in item"
              :key="d_index"
              :class="{
                mouse_on_day: mouseOnCurrentMonth(d_item, d_index),
                checked_wrap: isChecked(d_item),
              }"
              @click="handleMouseOn(d_item, d_index)"
            >
              <Icon
                name="checked"
                color="var(--theme)"
                v-if="isChecked(d_item)"
                size="28"
              />
              <div
                :class="{
                  current_month: monthClass(d_item.month),
                }"
                v-else
              >
                {{ d_item.date | getCD }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Popup v-model="datePickerShow" position="bottom">
      <DatetimePicker
        v-model="currentDate"
        type="year-month"
        @cancel="handleClose"
        @confirm="handleOk"
        :confirm-button-text="$t('Button.confirm')"
        :cancel-button-text="$t('Button.cancle')"
      />
    </Popup>
  </div>
</template>
<script>
import { DatetimePicker, Popup, Icon } from "vant";
export default {
  name: "Checkin",
  components: {
    DatetimePicker,
    Popup,
    Icon,
  },
  data() {
    this.monthStr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    this.weekStr = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return {
      // today: new Date(),
      year: "",
      month: "",
      day: "",
      date: "",
      startTime: "",
      endTime: "",
      dateArr: [],
      hasCheckin: false,
      mouseOnItem: 0,
      datePickerShow: false,
      currentDate: new Date(),
      selectdArr: [],
    };
  },
  // props: ["checkin", "times"],
  props: {
    checkin: {
      type: Array,
      default: () => [],
    },
    times: {
      type: Object,
      default: () => ({}),
    },
    today: {
      type: Date,
      default: () => new Date(),
    },
    disabledClick: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "times",
    event: "updateTimes",
  },
  created() {
    this.initDate();
    this.mouseOnItem = this.date;
  },
  filters: {
    getCD(val) {
      return val.split("/")[2];
    },
  },
  watch: {
    dateArr: {
      deep: true,
      handler: function (val, oldVal) {
        this.startTime = val[0][0].date;
        this.endTime = val[5][6].date;
        this.setMonth(this.year + "/" + this.month, [
          this.startTime,
          this.endTime,
        ]);
      },
    },
    today: {
      deep: true,
      handler() {
        this.initDate();
      },
    },
  },
  methods: {
    initDate() {
      this.year = this.today.getFullYear();
      this.month = this.today.getMonth() + 1;
      this.day = this.today.getDay();
      this.date = this.today.getDate();
      this.timeToParent();
      this.getCalendar();
      this.mouseOnItem = this.date;
    },
    timeToParent(year = this.year, month = this.month) {
      this.$emit("updateTimes", { year, month });
    },
    handleClose() {
      this.currentDate = this.getDate();
      this.datePickerShow = false;
    },
    getDate() {
      return new Date(`${this.year}-${this.month}-${this.date}`);
    },
    handleOk(val) {
      this.month = val.getMonth() + 1;
      this.year = val.getFullYear();
      this.currentDate = this.getDate();
      this.timeToParent(val.getFullYear(), val.getMonth() + 1);
      this.getCalendar();
      this.datePickerShow = false;
    },
    getItemDate(val) {
      return val.split("/")[2];
    },
    handleMouseOn(item, index) {
      if (this.disabledClick) return;
      const date = new Date(item.date);
      this.date = date.getDate();
      this.day = date.getDay();
      if (item.month === "pre") {
        this.prevMonth();
      } else if (item.month === "next") {
        this.nextMonth();
      }
      this.mouseOnItem = this.getItemDate(item.date);
    },
    mouseOnCurrentMonth(item, index) {
      if (item.month !== "cur") return false;
      return this.mouseOnItem == this.getItemDate(item.date);
    },
    isChecked(data) {
      // console.log(this.$format(data.date, "yyyy/MM/dd"),this.checkin);
      if (
        !!this.checkin.find(
          (item) =>
            this.$format(item, "yyyy/MM/dd") ==
            this.$format(data.date, "yyyy/MM/dd")
        )
      ) {
        return true;
      }
      return false;
    },
    //签到
    checkNow() {
      this.$emit("checkIn");
    },
    setMonth(date) {
      this.$emit("setMonth", date, [this.startTime, this.endTime]);
    },
    monthClass(type) {
      if (type != "cur") {
        return true;
      } else {
        return false;
      }
    },
    // getGold(thisDay) {
    //   for (let i in this.checkin) {
    //     var d = new Date(this.checkin[i].time.replace(/-/g, "/"));
    //     var _ymd =
    //       d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();

    //     if (new Date(thisDay).getTime() == new Date(_ymd).getTime()) {
    //       return this.checkin[i].amount;
    //     }
    //   }
    // },
    isLeap() {
      const year = this.year;
      if (year % 4 == 0 && year % 100 > 0) {
        return true;
      } else if (year % 400 == 0 && year % 3200 > 0) {
        return true;
      } else {
        return false;
      }
    },
    getLen(m) {
      const month = m || this.month;
      if (month == 2) {
        if (this.isLeap) {
          return 29;
        } else {
          return 28;
        }
      } else {
        if (month < 8) {
          if (month % 2 > 0) {
            return 31;
          } else {
            return 30;
          }
        } else {
          if (month % 2 > 0) {
            return 30;
          } else {
            return 31;
          }
        }
      }
    },
    getCalendarTime() {
      return this.year + "-" + this.month + "-" + this.date;
    },
    getCalendar() {
      var len = this.getLen();
      var d = new Date(this.year, this.month - 1, 1);
      var dfw = d.getDay();
      var arr = new Array();
      var tem = 0;
      var nextTem = 1;
      var pre = dfw - 1;

      var _lastLen = this.getLen(this.month - 1);

      for (var i = 0; i < 6; i++) {
        arr[i] = new Array();
        for (var j = 0; j < 7; j++) {
          tem++;
          if (tem - dfw > 0 && tem - dfw <= len) {
            arr[i][j] = {
              date: this.year + "/" + this.month + "/" + (tem - dfw),
              month: "cur",
            };
          } else {
            if (tem <= dfw) {
              arr[i][j] = {
                date:
                  this.year + "/" + (this.month - 1) + "/" + (_lastLen - pre),
                month: "pre",
              };
              pre--;
            } else {
              arr[i][j] = {
                date: this.year + "/" + (this.month + 1) + "/" + nextTem,
                month: "next",
              };
              nextTem++;
            }
          }
        }
      }
      this.dateArr = arr;
      // console.log(this.dateArr);
    },
    nextMonth() {
      if (this.month == 12) {
        this.year++;
        this.month = 1;
      } else {
        this.month++;
      }
      this.timeToParent();
      this.getCalendar();
    },
    prevMonth() {
      if (this.month == 1) {
        this.year--;
        this.month = 12;
      } else {
        this.month--;
      }
      this.timeToParent();
      this.getCalendar();
    },
    contains(arr) {
      if (
        arr[0] == "" &&
        arr[1] == "" &&
        arr[2] == "" &&
        arr[3] == "" &&
        arr[4] == "" &&
        arr[5] == "" &&
        arr[6] == ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    // isCheck(index) {
    //   for (let i in this.checkin) {
    //     var todayDate = new Date();
    //     var today =
    //       todayDate.getFullYear() +
    //       "/" +
    //       (todayDate.getMonth() + 1) +
    //       "/" +
    //       todayDate.getDate();
    //     var d = new Date(this.checkin[i].time.replace(/-/g, "/"));
    //     var _ymd =
    //       d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
    //     if (new Date(today).getTime() == new Date(_ymd).getTime()) {
    //       //今日已经签到
    //       this.hasCheckin = true;
    //     }

    //     if (new Date(index).getTime() == new Date(_ymd).getTime()) {
    //       // console.log('已经签到')
    //       return true;
    //     }
    //   }
    //   return false;
    // },
    doCheck(d) {
      var dString =
        new Date().getFullYear() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getDate();
      if (new Date(d).getTime() == new Date(dString).getTime()) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="less" scoped>
.calendar {
  // width: 100%;
  color: #000;
  * {
    font-weight: normal;
  }
  position: relative;
  .day {
    font-weight: 500;
    font-size: calc(20rem / 16);
    margin-left: calc(16rem / 16);
  }
  .current_month {
    color: #999;
  }
  .date_style {
    padding: 0;
  }
  .mouse_on_day {
    background: var(--theme);
    color: #fff;
  }
  .checked_wrap {
    background: transparent;
  }
}
.calendar h4 {
  line-height: 40px;
  background-color: #fff;
  text-align: center;
  color: #333;
  box-shadow: 0 0 4px #bbb;
  -webkit-box-shadow: 0 0 4px #bbb;
}
.calendar button {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 5px;
  color: #000;
  background: #f3f3f3;
  border: none;
}
.calendar button:focus {
  border: none;
  outline: none;
}
.calendar button.month-less {
  left: 10px;
}
.calendar button.month-add {
  right: 10px;
}
.calendar .sign_tab {
  width: 100%;
  border-collapse: collapse;
  // border: 1px solid #e8e8e8;
  border-top: 0;
  table-layout: fixed;
}
.calendar .sign_tab th {
  text-align: center;
  // height: 14.28571428571429vw;
  height: calc(38rem / 16);
  font-weight: 500;
}
.calendar .sign_tab td {
  position: relative;
  // border: 1px solid #eee;
  padding: calc(9rem / 16) 0;
  text-align: center;
  font-size: 14px;
  font-family: arial;
  > div {
    line-height: calc(28rem / 16);
  }
}
.calendar .sign_tab td.over {
  background-color: #fff;
  border-left: 0;
  border-right: 0;
}
.calendar .sign_tab td.disa {
  color: #888 !important;
  background: none !important;
  * {
    color: #888 !important;
  }
}
.calendar .sign_tab td.check_day {
  background-color: #f8f8f8;
  color: #58ce7a;
  position: relative;
}
.calendar {
  .ui-state-down,
  .ui-state-default {
    font-size: 10px;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 3px;
    left: 0;
  }
  .ui-state-down {
    color: #f60;
  }
}

.calendar .ui-state-up {
  font-size: 10px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 3px;
  left: 0;
}
.calendar .sign_tab td.cur_day {
  background-color: #ffd2d2;
  color: #fff;
}
.checkin-btn {
  display: block;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  font-size: 10px;
}
</style>